<template>
  <div class="lg:py-20 md:py-2 px-6 max-w-screen-xl mx-auto text-center">
    <div class="max-w-screen-xl">

      <!-- 我要卖资产 start-->
      <section class=" py-4">
        <h1 class=" text-3xl font-bold ">买卖资产，就找我们</h1>
        <p class="text-3xl mt-4 leading-loose">
          感谢您对我们平台的认可！
        </p>
        <p class=" index-desc text-2xl text-left leading-loose">
          如果您有买卖工厂、房产、公司、设备、矿山等需求，请在下面留下您的相关信息，或扫描二维码联系我们吧！
        </p>
        <div class="justify-center mt-8  mx-auto flex flex-col lg:flex-row lg:items-center lg:justify-center pl-8 pr-8">

          <form action="" class="md:w-2/3 lg:mt-0 text-left ">
            <div class="flex flex-wrap flex-col mb-6  w-full md:w-2/3">
              <label for="name" class="block mb-2 text-md   font-bold tracking-wide ">您的名字</label>
              <input type="text" v-model="formMess.name" class="block px-4 py-3 mb-2 w-full border-2 border-gray-200 focus:ring-0 focus:outline-none rounded  focus:border-primary bg-white leading-tight appearance-none
                  " placeholder="请输入您的称呼" ref="nameInput">

              <p v-if="errors.name" class="text-xs text-primary">{{ errors.name }}</p>
            </div>

            <div class="flex flex-wrap flex-col mb-6  w-full md:w-2/3">
              <label for="mobile" class="block mb-2 text-md   font-bold tracking-wide ">您的电话</label>
              <input type="text" v-model="formMess.mobile"
                class="block px-4 py-3 mb-2 w-full border-2 border-gray-200 rounded bg-white leading-tight appearance-none   focus:bg-white focus:ring-0 focus:outline-none   focus:border-primary"
                placeholder="请输入您的电话" ref="mobileInput">
              <p v-if="errors.mobile" class="text-xs text-primary">{{ errors.mobile }}</p>
            </div>

            <div class="flex flex-wrap flex-col mb-6 w-full md:w-2/3">
              <label for="msg" class="block mb-2 text-md   font-bold tracking-wide">您的资产信息</label>
              <textarea v-model="formMess.msg"
                class="no-resize resize-none appearance-none block w-full h-48 px-4 py-3 mb-2 border border-gray-200 rounded bg-white leading-tight   focus:bg-white focus:ring-0 focus:outline-none   focus:border-primary"
                placeholder="简单说下您的需求，我们将派相应的工作人员与您联系！" ref="msgInput"></textarea>
              <p v-if="errors.msg" class="text-xs text-primary">{{ errors.msg }}</p>
            </div>

            <!-- <div class="md:flex md:items-center">
              <button @click="onSubmit()"
                class="lg:w-1/2 w-full  px-4 py-2 rounded bg-primary shadow text-white font-bold focus:outline-none focus:shadow-outline hover:bg-red-600">提交</button>
            </div> -->

            <div class="but mt-6 lg:w-1/3 md:w-full bg-primary p-3 rounded text-center text-white text-xl"
              @click="onSubmit()">提交</div>
          </form>







          <div class="lg:w-1/3  bg-red-700  text-center p-3 mt-4 lg:mt-0">
            <h3 class=" text-2xl font-bold text-center mt-4">在线联系</h3>

            <img src="@/assets/mmbuy/hz/hz08.png" alt="" class="w-[180px]  mb-2 mt-3 mx-auto">
            <p class="  mb-4">扫描二维码，直接联系我们！</p>
          </div>

        </div>

      </section>

      <!-- 我要卖资产 end-->

      <LoadingOverlay :isLoading=" isLoading" />


    </div>



  </div>
  <!-- 提交成功弹窗 -->
  <TransitionRoot appear :show="isOpen" as="div">
    <Dialog as="div" @close="closeModal" class="relative z-50">

      <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0" enter-to="opacity-100"
        leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-black/25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center md:justify-center p-4 text-center">
          <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95">
            <DialogPanel
              class="w-80 md:w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-center align-middle shadow-xl transition-all">
              <DialogTitle as="h3" class="text-lg  leading-6 text-gray-900 font-bold">
                提交成功
              </DialogTitle>
              <div class="mt-4">
                <p class="text-sm text-gray-500">
                  您的资产相关信息已提交成功，稍后我们的工作人员将会与您联系。
                </p>
              </div>

              <div class="mt-4">
                <button type="button"
                  class="inline-flex justify-center rounded-md border border-transparent bg-primary px-4 py-2 text-sm font-medium text-white hover:bg-red-500 focus:outline-none  "
                  @click="closeModal">
                  好的
                </button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>




  <!-- Main section end -->
</template>

<script>
import { submitProperty } from "@/api/property";
import LoadingOverlay from '@/components/LoadingOverlay.vue';


import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from '@headlessui/vue'

// import axios from 'axios';
export default {
  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
    LoadingOverlay
  },
  data() {
    return {
      formMess: {
        "name": "",
        "mobile": "",
        "msg": ""
      },
      //校验信息
      errors: {
        name: "",
        mobile: "",
        msg: ""
      },

      isOpen: false,
      isLoading: false




    }
  },
  computed: {
    nameIsValid() {
      // 称呼校验  
      return this.formMess.name.trim() !== "";
    },

    mobileIsValid() {
      // 简单的手机格式校验  
      const mobileRegex = /^1(3|4|5|7|8|9)\d{9}$/;
      return mobileRegex.test(this.formMess.mobile);
    },
    msgIsValid() {
      // 信息提交校验 
      return this.formMess.msg.trim() !== "";
    },


  },
  watch: {
    'formMess.name'() {
      this.errors.name = this.nameIsValid ? '' : '请填写您的称呼';
    },
    'formMess.mobile'() {
      this.errors.mobile = this.mobileIsValid ? '' : '手机号格式错误';
    },
    'formMess.msg'() {

      this.errors.msg = this.msgIsValid ? '' : '请填写您的资产信息';
    }
  },
  methods: {
    async onSubmit() {
      // console.log('按钮点击', this.formMess);
      // 表单校验通过，执行提交操作  
      if (this.nameIsValid) {
        if (this.mobileIsValid) {
          if (this.msgIsValid) {
            /* json格式提交： */
            // let formData = JSON.stringify(this.formMess);
            this.isLoading = true;


            /* formData格式提交： */
            let formData = new FormData();
            for (var key in this.formMess) {
              formData.append(key, this.formMess[key]);
            }

            submitProperty(formData).then(res => {

              // console.log("res--->",res);
              this.isLoading = false;
              this.openModal(res);

            });

            // try {
            //   const response = await submitProperty(formData);
            //   console.log('Submit response:', response);
            //   if (response.code === 200) {
            //     this.openModal();
            //   }
            // } catch (error) {
            //   console.error('Error submitting property:', error);
            // }

          } else {
            this.$refs.msgInput.focus();
          }
        } else {
          this.$refs.mobileInput.focus();
        }
      } else {
        this.$refs.nameInput.focus();
      }

      // axios({
      //   method: "post",
      //   url: "https://spapi.qqspmmw.com/intention/saveintention",
      //   headers: {
      //     "Content-Type": "multipart/form-data"
      //   },
      //   withCredentials: false,
      //   data: formData
      // }).then((res) => {
      //   console.log(res);
      // });


    },
    // handleOverlayClick() {
    //   // 点击蒙层时可能想要执行的逻辑，比如关闭蒙层和吐司消息  
    //   this.showOverlay = false;
    //   // 根据你的需求，你可能还想同时关闭吐司消息  
    //   this.showComplete = false;
    // },

    closeModal() {
      this.isOpen = false
    },
    openModal() {
      this.isOpen = true
    },

  }
}

</script>

<style scoped>
.index-title {
  font-family: Microsoft YaHei;
  font-size: 48px;
  font-weight: 700;
  line-height: 63.35px;
  color: #BF242A;
}

.index-desc {
  font-family: Microsoft YaHei;
  font-size: 26px;
  text-indent: 2em;
  line-height: 40px;
}

.index-serve {
  font-family: Microsoft YaHei;
  font-size: 25px;
  line-height: 40px;
}

.red-dot-list {
  list-style-type: none;
  /* 移除默认的列表项符号 */
  padding-left: 0;
  /* 移除默认的内边距 */
}

.red-dot-list li {
  display: flex;
}

.red-dot-list li::before {
  content: '•';
  /* 使用红点 */
  color: #BF242A;
  /* 设置红点颜色 */
  font-size: 1.2em;
  /* 设置红点大小 */
  margin-right: 0.5em;
  /* 设置红点和文本之间的间距 */
}


.from_box form {
  width: 90%;
  margin: auto;
  border: .01rem solid gray;
  display: flex;
  flex-wrap: wrap;

}

.from_box input {
  width: 80%;
  height: .5rem;
  margin-bottom: .2rem;
  border: .01rem solid black;
  height: .5rem;
}


.from_box .but {


  font-size: .14rem;
  margin-left: 5%;

}
</style>