<template>
  <section class="px-0 lg:px-0 pt-[6.75rem] mx-auto">
    <img src="@/assets/mmbuy/hz/hz01.jpg" alt="" class=" w-full h-auto">
  </section>

  <div class="py-8  mx-auto text-center">

    <!-- 《中国全球商品买卖网》公司简介 start -->
    <section class="text-center  py-8 coodesc max-w-screen-1200  mx-auto p-4">
      <h1 class=" text-4xl font-bold text-primary">《中国全球商品买卖网》公司简介</h1>
      <p class=" mt-4 mx-auto index-desc lg:py-8 text-left">
        我们创建的《中国全球商品买卖网》互联网（供需）大平台，是一个创新的、多功能的、世界级的，具有AI人工智能技术的，为全球各个国家、各级政府、各类企业和个人提供综合服务的大平台；是一个为政府、企业、个人解决商海里问题和困难的大平台；是一个整合全球资源，让全球人参与、为全球人服务的大平台；是一个万亿级的大平台！平台的想象空间、创意空间、设计空间、市场空间、实战空间、未来发展空间无限美好！
      </p>
      <p class="mt-4 mx-auto index-desc lg:py-8 text-left">
        我们创新创建的《中国全球商品买卖网》项目，得到了多个城市领导的认可、赞誉和参与！得到了多位省级商会会长和企业家的认可、赞誉和参与！得到了多位学者的认可、赞誉和参与！得到了社会各界的认可、赞誉和参与！
      </p>
      <p class="mt-4 mx-auto index-desc lg:py-8 text-left">
        多个城市的领导，邀请我们将《中国全球商品买卖网》项目落户于所在城市。
      </p>

      <p class="mt-4 mx-auto index-desc lg:py-8 text-left">
        多个企业、多位企业家等要求参资入股，共同打造《中国全球商品买卖网》这个大平台......
      </p>


      <p class="mt-4 mx-auto index-desc lg:py-8 text-left">
        目前平台各项基础建设工作已经完善，平台已于2024年7月18日上线运营。
      </p>

      <p class="mt-4 mx-auto index-desc lg:py-8 text-left">
        百度可查到《中国全球商品买卖网》官网。
      </p>

      <p class="mt-4 mx-auto index-desc lg:py-8 text-left">
        可在苹果、华为、荣耀、小米手机应用市场搜索下载“24小时买卖网”APP 。
      </p>

    </section>
    <!-- 《中国全球商品买卖网》公司简介 end -->


    <!-- 《中国全球商品买卖网》公司估值、原始股数量设定 start -->
    <section class="text-center py-8 coodesc max-w-screen-1200  mx-auto p-4">
      <h1 class=" text-4xl font-bold text-primary">《中国全球商品买卖网》公司估值、原始股数量设定</h1>
      <p class="mt-4 mx-auto index-desc lg:py-8 text-left">
        1.公司现阶段为创建初期，公司总估值为人民币1亿元。
      </p>
      <p class="mt-4 mx-auto index-desc lg:py-8 text-left">
        2.公司设定原始股总数量为1亿股，每1股价值为人民币1元整。
      </p>
    </section>
    <!-- 《中国全球商品买卖网》公司估值、原始股数量设定 end -->

    <!-- 投资合作的优势start -->
    <div class=" bg-red ">
      <section class="text-center py-8 coodesc max-w-screen-1200  mx-auto my-auto  p-4">
        <h2 class=" text-4xl font-bold text-primary mb-8 mt-8">投资合作优势</h2>

        <div class="grid grid-cols-1 md:grid-cols-3 gap-4 mt-8 mb-4 items-center justify-center">

          <div class="bg-red-700 h-[580px] p-10  rounded-lg  flex flex-col items-center justify-center">
            <img class=" mb-5 w-20" src="@/assets/mmbuy/hz/hz10.png">
            <div class=" font-bold mb-5 text-3xl">独一无二的平台</div>
            <div class="  text-white text-center text-2xl h-[400px]">
              这是一个创新的、多功能的、世界级的，具有AI人工智能技术的，为全球各个国家、各级政府、各类企业和个人提供综合服务的大平台；是一个为政府、企业、个人解决商海里问题和困难的大平台；是一个整合全球资源，让全球人参与、为全球人服务的大平台；是一个万亿级的大平台！
            </div>
          </div>


          <div class="bg-red-700 h-[580px] p-10  rounded-lg  flex flex-col items-center justify-center">
            <img class=" mb-5 w-20" src="@/assets/mmbuy/hz/hz11.png">
            <div class=" font-bold mb-5 text-3xl">平台名称的创新</div>
            <div class="  text-white text-center text-2xl h-[400px]">
              平台名称，通俗易懂，本身就是一句举世无双，让世人过目难忘的广告词！<br />
              这个平台的名称，全世界无论是白皮肤人、黑皮肤人、黄皮肤人，一看就懂！一看就能记住！一看就想了解和使用这个世界级的大平台！
            </div>
          </div>

          <div class="bg-red-700 h-[580px] p-10  rounded-lg  flex flex-col items-center justify-center">
            <img class=" mb-5 w-20" src="@/assets/mmbuy/hz/hz12.png">
            <div class=" font-bold mb-5 text-3xl">平台LOGO的创新</div>
            <div class="  text-white text-center text-2xl h-[400px]">
              计划请三位世界级的名人：一是白皮肤人，二是黑皮肤人，三是黄皮肤人，他（她）们都是倡导世界和平，倡导人间大爱的名人。三位名人手拉手、肩并肩、面带笑容的LOGO图片。
            </div>
          </div>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-3 gap-4 pb-4  items-center justify-center">
          <div class="bg-red-700 h-[350px] p-10  rounded-lg  flex flex-col items-center justify-center">
            <img class=" mb-5 w-20" src="@/assets/mmbuy/hz/hz13.png">
            <div class=" font-bold mb-5 text-3xl">创新的思想理论支撑</div>
            <div class="  text-white text-center text-2xl h-[100px]">
              在总结国内国际互联网平台的基础上，进行了思想理论与技术等创新。
            </div>
          </div>
          <div class="bg-red-700 h-[350px] p-10  rounded-lg  flex flex-col items-center justify-center">
            <img class=" mb-5 w-20" src="@/assets/mmbuy/hz/hz14.png">
            <div class=" font-bold mb-5 text-3xl">创新的服务功能</div>
            <div class="  text-white text-center text-2xl h-[100px]">
              具有全球商品买卖、全球资产买卖、全球找工作、全球招商引资等。
            </div>
          </div>

          <div class="bg-red-700 h-[350px] p-10  rounded-lg  flex flex-col items-center justify-center">
            <img class=" mb-5 w-20" src="@/assets/mmbuy/hz/hz15.png">
            <div class=" font-bold mb-5 text-3xl">创新的运营模式</div>
            <div class="  text-white text-center text-2xl h-[100px]">
              通过线上、线下双轮驱动，实现“买全球、卖全球”。
            </div>
          </div>
        </div>


        <div class="grid grid-cols-1 md:grid-cols-3 gap-4 pb-4  items-center justify-center">
          <div class="bg-red-700 h-[450px] p-10  rounded-lg  flex flex-col items-center justify-center">
            <img class=" mb-5 w-20" src="@/assets/mmbuy/hz/hz16.png">
            <div class=" font-bold mb-5 text-3xl md:h-20">顶级的专业团队开发运营</div>
            <div class="  text-white text-center text-2xl h-[200px]">

              顶级的技术团队开发，顶级的市场团队运营。
            </div>  
          </div>

          <div class="bg-red-700 h-[450px] p-10  rounded-lg  flex flex-col items-center justify-center">
            <img class=" mb-5 w-20" src="@/assets/mmbuy/hz/hz17.png">
            <div class=" font-bold mb-5 text-3xl md:h-20">利润收益可观</div>
            <div class="  text-white text-center text-2xl h-[200px]">
              规划上市，投资回报翻倍
            </div>
          </div>

          <div class="bg-red-700 h-[450px] p-10  rounded-lg  flex flex-col items-center justify-center">
            <img class=" mb-5 w-20" src="@/assets/mmbuy/hz/hz18.png">
            <div class=" font-bold mb-5 text-3xl md:h-20">利润分配</div>
            <div class="  text-white text-center text-2xl h-[200px]">
              每年将本区域销售产生的利润总金额的25%分佣给城市经理；每年年末按投资金额占股比例给予奖励；公司上市按股东占股比例享受股权红利。
            </div>
          </div>
        </div>
      </section>

    </div>

    <!-- 投资合作的优势end -->


    <!-- 城市经理（原始股东）的条件  start-->

    <section class="text-center py-8 coodesc max-w-screen-1200  mx-auto  p-4 ">
      <h2 class=" text-4xl font-bold text-primary mb-8 mt-8">城市经理（原始股东）的条件</h2>
      <div class="grid grid-cols-1 md:grid-cols-3 gap-4 mt-8 mb-4 items-center justify-center">
        <div class="bg-red h-[450px]    flex flex-col   border-2 border-gray-100">
          <img class=" mb-8 " src="@/assets/mmbuy/hz/hz20.png">
          <div class=" font-bold text-left text-2xl px-10 mb-6">共同成就一份事业</div>
          <div class="  text-white text-left text-xl h-[100px] px-10">
            认同公司经营理念、企业文化及品牌价值。对品牌充满信心，有与公司共同发展的强烈愿望。
          </div>
        </div>

        <div class="bg-red h-[450px]    flex flex-col   border-2 border-gray-100">
          <img class=" mb-8 " src="@/assets/mmbuy/hz/hz21.png">
          <div class=" font-bold text-left text-2xl px-10 mb-6">诚实可信、品行端正</div>
          <div class="  text-white text-left text-xl h-[100px] px-10">
            积极配合公司的市场运作，具有契约精神、遵纪守法。
          </div>
        </div>

        <div class="bg-red h-[450px]    flex flex-col   border-2 border-gray-100">
          <img class=" mb-8 " src="@/assets/mmbuy/hz/hz22.png">
          <div class=" font-bold text-left text-2xl px-10 mb-6">综合能力强</div>
          <div class="  text-white text-left text-xl h-[100px] px-10">
            个人的综合能力强，有一定经济实力。
          </div>
        </div>
      </div>
    </section>
    <!-- 城市经理（原始股东）的条件  end-->


    <!-- 代理商（股东、经理）交付标准  start-->
    <div class=" bg-red ">
      <section class="text-center py-8 coodesc max-w-screen-1200  mx-auto  p-4">
        <h2 class=" text-4xl font-bold text-primary mb-8 mt-8">代理商（股东、经理）交费标准</h2>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-32 mt-16  items-center justify-center">
          <div class=" h-[100px]     flex flex-col items-center justify-center">
            <div class="  text-center text-2xl h-[100px]">
              省级代理商（终身）
            </div>
            <div class=" font-bold mb-5 text-3xl">200万</div>
            <div class="  mb-5 text-3xl">占股 2%</div>
          </div>

          <div class=" h-[100px]    flex flex-col items-center justify-center">
            <div class="   text-center text-2xl h-[100px]">
              市级代理商（终身）
            </div>
            <div class=" font-bold mb-5 text-3xl">50万</div>
            <div class="  mb-5 text-3xl">占股 0.5%</div>
          </div>

          <div class=" h-[100px]     flex flex-col items-center justify-center">
            <div class="   text-center text-2xl h-[100px]">
              县（区）代理商（终身）
            </div>
            <div class=" font-bold mb-5 text-3xl">20万</div>
            <div class="  mb-5 text-3xl">占股 0.2%</div>
          </div>

        </div>

        <div class="text-left text-2xl  mt-4 mb-4 ">
          注：每人限购2%以下（含2%）股权，特殊情况另议。国外城市经理（原始股东）参照国内城市经理标准执行。
        </div>


      </section>

    </div>

    <!-- 代理商（股东、经理）交费标准  end-->




    <!-- 代理商的条件 start -->

    <!-- <section class="text-center py-8 coodesc max-w-screen-1200  mx-auto  p-4">
      <h2 class=" text-4xl font-bold text-primary mb-8 mt-8 ">代理商的条件</h2>
      <div class="grid grid-cols-1 md:grid-cols-4 gap-2 mt-8  items-center justify-center">
        <div class="bg-F8F7FF h-[300px] p-8 m-1   flex flex-col pt-15 ">
          <div class=" font-bold mb-5 text-2xl h-14">共同成就一份事业</div>
          <div class="  text-gray-500 text-left text-xl">
            认同公司经营理念、企业文化及品牌价值。对品牌充满信心，有与公司共同发展的强烈愿望。
          </div>
        </div>
        <div class="bg-FFFAEF h-[300px] p-8 m-1   flex flex-col pt-15">
          <div class=" font-bold mb-5 text-2xl h-14">诚实可信、品行端正</div>
          <div class="  text-gray-500 text-left text-xl">
            积极配合公司的市场运作，具有契约精神、遵纪守法。
          </div>
        </div>
        <div class="bg-FFF8F8 h-[300px] p-8 m-1   flex flex-col pt-15">
          <div class=" font-bold mb-5 text-2xl h-14">综合能力强</div>
          <div class="  text-gray-500 text-left text-xl">
            个人的综合能力强，有一定经济实力。
          </div>
        </div>

        <div class="bg-F1FDFF h-[300px] p-8 m-1   flex flex-col pt-15">
          <div class=" font-bold mb-5 text-2xl h-14">代理商要求</div>
          <div class="  text-gray-500 text-left text-xl">
            省级代理商：100万<br />
            市级代理商：50万<br />
            县(区)代理商：20万<br />
          </div>
        </div>
      </div>
    </section> -->

    <!-- 城市经理（原始股东）的设立 start -->
    <section class="text-center py-8 coodesc max-w-screen-1200  mx-auto p-4  ">
      <h1 class=" text-4xl font-bold text-primary mb-8 mt-16">城市经理（原始股东）的设立</h1>


      <ul class="list-disc text-left space-y-4 text-2xl m-8">
        <li>国内每个省、市、县（区）只设一名城市经理（原始股东）。</li>
        <li>全球各个国家，每座城市只设一名城市经理（原始股东）。</li>
        <li>因为原始股数量有限，前期上任的城市经理有机会成为原始股东；后期上任的城市经理无法成为原始股东。</li>
        <!-- ... -->
      </ul>

    </section>
    <!-- 城市经理（原始股东）的设立 end -->


    <!-- 代理商的回报 start -->
    <!-- <div class="bg-FFF5F5">
      <section class="text-center py-8 coodesc max-w-screen-1200  mx-auto  p-4 ">
        <h2 class=" text-primary text-4xl font-bold  mb-8 mt-8">代理商的回报</h2>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-32 mt-8  items-center justify-center">
          <div class="  m-10  h-16 flex flex-col pt-15  border-l-2 border-red-700 justify-center ">
            <div class=" pl-3  text-gray-500 text-left text-xl ">
              本区域销售产生的利润10%奖励给各级代理商
            </div>
          </div>
          <div class="  m-10  h-16 flex flex-col pt-15  border-l-2 border-red-700 justify-center ">
            <div class=" pl-3  text-gray-500 text-left text-xl ">
              每年年末按投资人的投资额占股比例给予奖励
            </div>
          </div>
          <div class="  m-10  h-16 flex flex-col pt-15  border-l-2 border-red-700 justify-center ">
            <div class=" pl-3  text-gray-500 text-left text-xl ">
              公司上市按股东占股比例分红
            </div>
          </div>
        </div>
      </section>
    </div> -->
    <!-- 代理商的回报 end -->


    <!-- 城市经理（原始股东）的待遇 start -->
    <div class="bg-red">
      <section class="text-center py-8 coodesc max-w-screen-1200  mx-auto  p-4 ">
        <h2 class=" text-primary text-4xl font-bold  mb-8 mt-8">城市经理（原始股东）的待遇</h2>
        <div class=" gap-32 mt-8  items-center justify-center">
          <div class="  m-10  h-12 flex flex-col pt-15  border-l-2 border-white justify-center ">
            <div class=" pl-3   text-left text-2xl ">
              每年将本区域销售产生的利润总金额的25%分佣给城市经理（原始股东）
            </div>
          </div>
          <div class="  m-10  h-12 flex flex-col pt-15  border-l-2 border-white justify-center ">
            <div class=" pl-3   text-left text-2xl ">
              每年年末按投资金额占股比例给予奖励（具体数额由公司决定）
            </div>
          </div>
          <div class="  m-10  h-12 flex flex-col pt-15  border-l-2 border-white justify-center ">
            <div class=" pl-3   text-left text-2xl ">
              公司上市按股东占股比例享受股权红利
            </div>
          </div>

          <div class="  m-10  h-100 flex flex-col pt-15  border-l-2 border-white justify-center ">
            <div class=" pl-3 text-left text-2xl ">
              <p>城市经理（原始股东）可作为推荐人身份，全国、全球发展新的城市经理（原始股东），推荐新的城市经理（原始股东）成功后，将新城市经理（原始股东）缴费总金额的50%奖励给推荐人，其中</p>
              <p>（1）25%作为奖金，奖励给推荐人本人。</p>
              <p>（2）25%作为推荐人本部门建设运营发展基金（使用时需公司审批发放）。</p>
            </div>
          </div>

          <div class="  m-10  h-100 flex flex-col pt-15  border-l-2 border-white justify-center ">
            <div class=" pl-3   text-left text-2xl ">
              <p>城市经理（原始股东）提供给公司的大项目，如买卖土地、矿山、工厂、公司（包括工业、农业、科技、服务业项目）等，交易成功后，产生利润总额的50%分佣给城市经理（原始股东）。</p>
            </div>
          </div>

          <div class="  m-10  h-12 flex flex-col pt-15  border-l-2 border-white justify-center ">
            <div class=" pl-3   text-left text-2xl ">
              城市经理（原始股东）可全国、全球开展工作。
            </div>
          </div>

        </div>
      </section>
    </div>
    <!-- 城市经理（原始股东）的待遇 end -->

    <!-- 盈利模式 start-->
    <section class="text-center px-10 coodesc max-w-screen-1200  mx-auto   ">
        <h2 class=" text-4xl font-bold text-primary">《中国全球商品买卖网》的盈利模式</h2>
        <div class="mt-4 max-w-screen-1200 lg:flex-row lg:items-center lg:gap-8 lg:py-8">
          <div class=" mt-10 lg:mt-0 lg:px-0 px-10 text-left index-serve m-auto mb-10">
            <ul class="red-dot-list list-outside space-y-6 ">
              <li>公司全球商品买卖盈利</li>
              <li>公司全球资产买卖盈利</li>
              <li>公司为全球人才、劳动力，找工作；为政府、企业、个人提供人才、劳动力服务盈利</li>
              <li>公司为政府、企业、个人全球招商引资，对接项目盈利</li>
              <li>公司为政府、企业、个人全球做广告、广告做全球盈利</li>
              <li>公司成立全球网红协会，为政府、企业、个人带货盈利</li>
              <li>公司为政府、企业、个人产品创建全球“品牌”服务盈利</li>
              <li>公司为政府、企业、个人全球代运营销售产品盈利 </li>
              <li>公司发展全国各省、市、县、区和全球各个国家城市经理（原始股东）盈利</li>
              <li>公司为全球商协会服务盈利</li>
              <li>公司组织举办全球企业家交友大会、合作项目盈利</li>
              <li>公司开发《中国全球商品买卖网》自己的“品牌”产品，销售盈利</li>
              <li>公司创办“网红培训学校、互联网大学”盈利</li>
              <li>公司自身的“品牌”建设价值增值盈利</li>
              <li>公司“上市”盈利</li>
            </ul>
          </div>
          
        </div>
      </section>
      <!-- 盈利模式 end-->

    <!-- 诚招海外代理商start -->

    <!-- <picture>
            <source srcset="@/assets/mmbuy/hz/hz06.png" class="w-full h-auto" >
            <img src="@/assets/mmbuy/hz/hz06.png" alt="" class=" w-full h-auto">
          </picture> -->

    <!-- 诚招海外代理商end -->

    <section class="text-center max-w-screen-1200  mx-auto px-8 ">
      <div class="mt-4 max-w-screen-xl mx-auto flex flex-col lg:flex-row lg:items-center">
        <div class=" text-left hz-phone">
          <p class=" text-xl mt-3 mb-10">如果您对我们感兴趣，请联系我们，期待您的加入！</p>

          <p class="text-2xl font-bold">联系人：</p>
          <p class="font-bold text-2xl mt-2">李先生 18624266888 </p>

        </div>
      </div>
    </section>

  </div>
</template>

<script>

</script>

<style scoped>

.hz-phone_last_p {
  font-family: Microsoft YaHei;
  font-size: 40px;
  line-height: 64px;
  text-align: left;
  color: #444444;
}

.coodesc p {
  font-weight: 400;
  line-height: 46px;
  color: #fff;
}

.red-dot-list1 li {
  display: flex;
  font-family: Microsoft YaHei;
  font-size: 24px;
}

.red-dot-list1 li::before {
  content: '•';
  /* 使用红点 */
  color: #444444;
  /* 设置红点颜色 */
  font-size: 1.2em;
  /* 设置红点大小 */
  margin-right: 0.5em;
  /* 设置红点和文本之间的间距 */
}


.my-red-text {
  color: #BF242A;
}
</style>
