<template>
    <div class="flex items-center justify-between border-t border-gray-200  px-4 py-3 sm:px-6">
      <div class="flex flex-1 justify-between sm:hidden">
        <button @click="goPre" :disabled="preDisable" class="relative inline-flex items-center rounded-md border border-gray-300 
            bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50
            disabled:bg-gray-200 disabled:text-gray-400 disabled:cursor-not-allowed">上一页</button>
        <button @click="goNext" :disabled="nextDisable" class="relative mr-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2
             text-sm font-medium text-gray-700 hover:bg-gray-50
             disabled:bg-gray-200 disabled:text-gray-400 disabled:cursor-not-allowed">下一页</button>
      </div>
  
      <div v-if="this.pageLength>0" class="hidden sm:flex sm:flex-1 justify-center">
        <div>
          <nav class="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
            <button @click="goPre" :disabled="preDisable" class="relative inline-flex items-center rounded-md px-2 py-2 text-gray-400 ring-1 ring-inset
                 ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0
                disabled:bg-gray-200 disabled:text-gray-400 disabled:cursor-not-allowed">
              上一页
            </button>
            <ul class="flex">
              <li v-for="page in pages" :key="page" ref="pages" class="flex items-center px-2">
                <button @click="jumpToPage(page)" v-if="isShowBtn(page)"
                        :class="{ 'bg-primary text-white': current === page, 'bg-white text-gray-700': current !== page }"
                        class="px-4 py-2 border rounded-md hover:bg-red-500">{{ page }}</button>
                <div v-else-if="isShowEllipsis(page)" class="ellipsis">&#8230;</div> <!--省略号-->
              </li>
            </ul>
            <button @click="goNext" :disabled="nextDisable" class="relative inline-flex items-center rounded-md px-2 py-2 text-gray-400 ring-1 ring-inset 
                ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0
                disabled:bg-gray-200 disabled:text-gray-400 disabled:cursor-not-allowed">
              下一页
            </button>
          </nav>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      total: {
        type: Number,
        default: 0
      }
    },
    data() {
      return {
        current: 1, // Current page
        pageSize: 50, // Items per page
        pages: [], // Page numbers
        pageLength: 0 // Total number of pages
      }
    },
    computed: {
      preDisable() { // Disable previous button
        return this.current === 1
      },
      nextDisable() { // Disable next button
        return this.current === this.pageLength
      }
    },
    watch: {
      total() { // Watch total items
        this.getPagesLength()
      },
      current(val) { // Watch current page
        this.$emit('change', val)
      }
    },
    created() { // On component creation
      this.getPagesLength()
    },
    methods: {
      getPagesLength() { // Calculate total pages
        const more = this.total % this.pageSize ? 1 : 0
        this.pageLength = Math.floor(this.total / this.pageSize) + more
        this.pages = Array.from({ length: this.pageLength }, (_, i) => i + 1)
        
      },
      jumpToPage(index) { // Jump to a specific page
        this.current = index
      },
      goPre() { // Previous page
        if (this.current > 1) this.current -= 1
      },
      goNext() { // Next page
        if (this.current < this.pageLength) this.current += 1
      },
      isShowBtn(index) { // Determine if the page button should be shown
        if (this.pageLength < 8) {
          return true
        } else {
          if (index === 1 || index === this.pageLength) {
            return true
          } else {
            if (this.current < 4 && index < 6) {
              return true
            } else if (this.current > this.pageLength - 4 && index > this.pageLength - 6) {
              return true
            } else if (index < this.current + 3 && index > this.current - 3) {
              return true
            } else {
              return false
            }
          }
        }
      },
      isShowEllipsis(index) { // Determine if ellipsis should be shown
        return index === 2 || index === this.pageLength - 1
      }
    }
  }
  </script>
  
  <style scoped>
  button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  </style>
  