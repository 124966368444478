<template>
    <div class="w-screen mx-auto justify-center bg-red">
        <div class=" mx-auto content-center z-10 border-b-[1px] border-b-white ">
            <ul class="min-h-24 md:flex max-w-screen-1200 mx-auto list-none p-2 m-0">
                <li class="text-center content-center py-2 h-12 sm:h-16  focus:outline-none">
                    资产类型：
                </li>
                <li v-for="item in propertyTabRes.category_list" :key="item.id" @click="setActive(item.id)"
                    :class="{ 'text-primary': activeIndex === item.id }"
                    class="h-12 sm:h-16 px-4 content-center bg-red hover:bg-red-700 focus:outline-none text-center">
                    <button>
                        {{ item.category_name }}
                    </button>
                </li>
            </ul>
        </div>
        
        <section class="text-center py-8 coodesc max-w-screen-1200 mx-auto">
            <div class="grid grid-cols-2 md:grid-cols-3 gap-4 mt-8 mb-4 items-center justify-center">
                <div v-for="(item, id) in propertyResData.list" :key="id" @click="openModal"
                    class="bg-red-700 flex flex-col border-2 border-gray-100">
                    <img class="w-full aspect-square mb-8" :src="item.goods_image">
                    <p class="h-20 font-bold text-left md:text-xl mx-2 md:px-6 mb-6">
                        {{ item.goods_name }}
                    </p>
                </div>
            </div>
        </section>
        <PropertyPages :total="propertyResData.total" @changed="onPageChanged" />
        <TransitionRoot appear :show="isOpen" as="template">
            <Dialog as="div" @close="closeModal" class="relative z-50">
                <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0"
                    enter-to="opacity-100" leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0">
                    <div class="fixed inset-0 bg-black/25" />
                </TransitionChild>
                <div class="fixed inset-0 overflow-y-auto">
                    <div class="flex min-h-full items-center justify-center p-4 text-center">
                        <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95"
                            enter-to="opacity-100 scale-100" leave="duration-200 ease-in"
                            leave-from="opacity-100 scale-100" leave-to="opacity-0 scale-95">
                            <DialogPanel
                                class="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-center md:align-middle shadow-xl transition-all">
                                <DialogTitle as="h3" class="text-lg leading-6 text-gray-900 font-bold">
                                    咨询客服
                                </DialogTitle>
                                <div class="mt-4">
                                    <img src="@/assets/mmbuy/hz/hz08.png" alt=""
                                        class="w-md:max-w-xs mb-2 mt-3 mx-auto">
                                    <span class="text-sm text-gray-500">
                                        您对我们的资产感兴趣，请扫描二维码，咨询我们，我们将为您提供一对一专业的服务。
                                    </span>
                                </div>
                                <div class="mt-4">
                                    <button type="button"
                                        class="inline-flex justify-center rounded-md border border-transparent bg-primary px-4 py-2 text-sm font-medium text-white hover:bg-red-500 focus:outline-none"
                                        @click="closeModal">
                                        好的
                                    </button>
                                </div>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </Dialog>
        </TransitionRoot>
        <LoadingOverlay :isLoading="isLoading" />
    </div>
</template>

<script>
import { propertyTab, propertyList } from "@/api/property";
import PropertyPages from '@/components/PropertyPages.vue';
import LoadingOverlay from '@/components/LoadingOverlay.vue';
import { TransitionRoot, TransitionChild, Dialog, DialogPanel, DialogTitle } from '@headlessui/vue';

export default {
    components: {
        PropertyPages,
        TransitionRoot,
        TransitionChild,
        Dialog,
        DialogPanel,
        DialogTitle,
        LoadingOverlay
    },
    data() {
        return {
            propertyTabReq: { group_id: "26" },
            propertyTabRes: { category_list: [] },
            propertyData: {
                sort_desc: "1",
                sort_type: "1",
                category_id: "243",
                group_id: "26",
                city: "大连",
                pagesize: "50",
                page: "1",
                is_sort: "0"
            },
            propertyResData: {
                current_page: 1,
                list: [],
                page_size: 50,
                pages: 1,
                total: 0
            },
            activeIndex: 246,
            isOpen: false,
            isLoading: false
        };
    },
    created(){
        this.fetchPropertyTab();
        this.fetchPropertyList();
    },
    mounted() {
        
    },
    methods: {
        async fetchPropertyTab() {
            this.isLoading = true;
            try {
                const response = await propertyTab(this.propertyTabReq);
                this.propertyTabRes = response;
                // console.log("propertyTabRes", this.propertyTabRes);
                const id=this.propertyTabRes.category_list[0].id;
                this.setActive(id);
            } catch (error) {
                console.error("Failed to fetch property tabs", error);
            } finally {
                this.isLoading = false;
            }
        },
        async fetchPropertyList() {
            this.isLoading = true;
            try {
                const response = await propertyList(this.propertyData);
                this.propertyResData = response;
                console.log("propertyResData", this.propertyResData);
            } catch (error) {
                console.error("Failed to fetch property list", error);
            } finally {
                this.isLoading = false;
            }
        },
        setActive(id) {
            // console.log("id--->",id);
            this.activeIndex = id;
            this.propertyData.category_id = id;
            this.fetchPropertyList();
        },
        onPageChanged(page) {
            this.propertyData.page = page;
            this.fetchPropertyList();
        },
        closeModal() {
            this.isOpen = false;
        },
        openModal() {
            this.isOpen = true;
        }
    }
};
</script>

<style scoped></style>