<script setup>

</script>

<template>
  <div class="contact-view ">
    <section class="px-0 lg:px-0 pt-[6.75rem] mx-auto">
      <img src="@/assets/mmbuy/contact/Mask01.png" alt="" class=" w-full h-auto">

    </section>
  
      <section class="text-center mt-8 pb-10 ">
        <h1 class="font-bold text-3xl">联系我们</h1>

        <div class=" max-w-screen-1200 justify-center mt-8  mx-auto flex flex-col lg:flex-row lg:items-center lg:justify-center pl-8 pr-8">

          <div class="lg:w-1/2 lg:mt-0 text-left hz-phone  ju">
            <p class="hz-phone_p flex items-center font-bold">
              <!-- <img class="mr-4 w-6" src="@/assets/mmbuy/contact/Frame1.png" alt="地址图标" /> -->
             联系地址：辽宁省大连市沙河口区富鸿国际大厦A座13层
            </p>

            <p class="font-bold hz-phone_last_p flex items-center mt-4">
              <!-- <img class="mr-4 w-6" src="@/assets/mmbuy/contact/Frame2.png" alt="电话图标" /> -->
              联系电话：0411- 84641106
            </p>

            <div class="flex space-x-16 mt-8 ">
              <div class="rounded-md p-2 text-center">
                <div class="w-36 h-36 bg-gray-300 mx-auto mb-2">
                  <img src="@/assets/mmbuy/hz/hz08.png" alt="" />
                </div>
                <p class="">添加企业微信</p>
              </div>
              <!-- <div class="rounded-md p-2 text-center">
                <div class="w-36 h-36 bg-gray-300 mx-auto mb-2"></div>
                <p class="text-gray-700">添加微信好友</p>
              </div> -->
            </div>
          </div>

          <div class="lg:w-1/2  lg:mt-0 text-center  lg:ml-8 ">
            <img src="@/assets/mmbuy/contact/Mask02.png" alt="" class="w-auto h-72 mx-auto">

          </div>
        </div>
      </section>


  </div>
</template>

<style scoped></style>
