<template>
  <!-- Navbar start -->
  <!-- <div class="max-w-screen-1200 flex justify-between "> -->

  <nav class="w-screen fixed bg-opacity-90 bg-white p-6 flex z-40 items-center justify-center" @scroll="handlerScroll">
    <div class="w-full max-w-screen-xl	 flex justify-between">

      <!-- Logo start -->
      <div class="flex justify-center items-center  mmlogo">
        <router-link to="/" role="menuitem">
          <img src="@/assets/mmbuy/logo.png" alt="Logo" class="w-full h-full mb-3">
        </router-link>
      </div>

      <!-- Logo end-->


      <div v-if="!mobileMenu" class="flex md:hidden">
        <button type="button" class="ml-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-stone-700"
          @click="mobileMenu = true">

          <i class="fa-solid fa-bars text-2xl text-red"></i>
        </button>
      </div>


      <!-- Toggler start -->
      <!-- <button role="menubar" class="md:hidden" @click="navToggle()">
        <i class="fa-solid fa-bars text-2xl text-primary"></i>
         
      </button> -->

      <!-- mobile menu -->
      <Dialog as="div" :open="mobileMenu" @close="mobileMenu = false">
        <DialogPanel focus="true" class="fixed inset-0 z-50 overflow-y-auto bg-white px-6 py-6 md:hidden">
          <div class="flex justify-between  items-center">
            <!-- LOGO -->
            <div class="flex justify-center items-center  mmlogo">
              <router-link to="/" role="menuitem">
                <img src="@/assets/mmbuy/logo.png" alt="Logo" class="w-full h-full mb-3">
              </router-link>
            </div>

            <button type="button" class="ml-2.5 p-2.5 rounded-md   text-red " @click="mobileMenu = false">
              <i class="fa-solid fa-xmark  text-3xl text-red  "></i>
            </button>
          </div>
          <div class="mt-6 flow-root">
            <div class="-my-6 divide-y divide-gray-400/10">
              <div class="space-y-2 py-6">
                <template v-for="item in navigation" :key="item.title">
                  <Disclosure v-if="item.children">
                    <DisclosureButton
                      class="-mx-3 text-left block w-full rounded-lg py-2 px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-400/10">
                      {{ item.title }}

                      <i class="fa-solid fa-chevron-down w-5 h-5 ml-2 text-gray-900"></i>

                    </DisclosureButton>
                    <DisclosurePanel class="text-gray-500 px-4 border-l">
                      <router-link v-for="child in item.children" :key="child.title" :to="child.to"
                        class="-mx-3 block rounded-lg py-2 px-3 text-base font-medium leading-7 text-gray-900 hover:bg-gray-400/10"
                        @click="mobileMenu = false">
                        {{ child.title }}
                      </router-link>
                    </DisclosurePanel>
                  </Disclosure>
                  <router-link v-else :to="item.to"
                    class="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-400/10"
                    @click="mobileMenu = false">
                    {{ item.title }}
                  </router-link>
                </template>
              </div>

            </div>
          </div>
        </DialogPanel>
      </Dialog>


      <!-- Toggler end -->

      <!-- Menu content start -->
      <div
        class=" absolute left-0 right-0 translate-y-16 bg-opacity-90 bg-white  md:bg-none md:bg-opacity-0 shadow hidden md:flex flex-col gap-4 items-center p-4 md:flex-row md:static md:shadow-none md:translate-y-0"
        role="menu" aria-expanded="false" >
        <!-- Links start -->

        <router-link to="/" role="menuitem" :class="{ 'active-link': active == 0 }" @mouseenter="showMore(false)">
          <p class="mx-4 text-stone-700" @click="isActive('/')" >首页</p>
        </router-link>
        <router-link @click="setActive(1)" @mouseenter="showMore(true)" to="/about" role="menuitem"
          :class="{ 'active-link': active == 1 }">
          <p class="mx-4 text-stone-700" @click="isActive('/about')">关于我们</p>
        </router-link>

        <router-link to="/property" role="menuitem" :class="{ 'active-link': active == 2 }"
          @mouseenter="showMore(false)">
          <p class="mx-4 text-stone-700" @click="isActive('/property')">资产信息</p>
        </router-link>

        <router-link to="/cooper" role="menuitem" :class="{ 'active-link': active == 3 }">
          <p class="mx-4 text-stone-700" @click="isActive('/cooper')">招商合作</p>
        </router-link>
        <router-link to="/contact" role="menuitem" :class="{ 'active-link': active == 4 }">
          <p class="mx-4 text-stone-700" @click="isActive('/contact')">联系我们</p>
        </router-link>

        <!-- Links end -->
      </div>

      <div v-if="moreVisible" @mouseleave="showMore(false)"
        class="absolute top-[5.7rem] left-0 w-screen  bg-gray-100 text-gray-700  shadow-lg flex   z-10 h-28 max-h-28   justify-center">
        <div class=" w-1/4 bg-gray-100 "></div>

        <button @click="setActive(1)" :class="{ 'text-red': activeIndex === 1  }"
          class=" px-4 py-2 bg-gray-100 hover:bg-gray-200 focus:outline-none mr-8">董事长的初心
        </button>
        <button @click="setActive(2)" :class="{ ' text-red': activeIndex === 2 }"
          class="px-4 py-2 bg-gray-100 hover:bg-gray-200 focus:outline-none mr-8">公司简介
        </button>
        <button @click="setActive(3)" :class="{ ' text-red': activeIndex === 3 }"
          class="px-4 py-2 bg-gray-100 hover:bg-gray-200 focus:outline-none mr-8">企业领导
        </button>
        <button @click="setActive(7)" :class="{ ' text-red': activeIndex === 7 }"
          class="px-4 py-2 bg-gray-100 hover:bg-gray-200 focus:outline-none mr-8">顾问团领导
        </button>
        <button @click="setActive(4)" :class="{ ' text-red': activeIndex === 4 }"
          class="px-4 py-2 bg-gray-100 hover:bg-gray-200 focus:outline-none mr-8">领导团队
        </button>
        <button @click="setActive(5)" :class="{ ' text-red': activeIndex === 5 }"
          class="px-4 py-2 bg-gray-100 hover:bg-gray-200 focus:outline-none mr-8">组织架构
        </button>
        <button @click="setActive(6)" :class="{ ' text-red': activeIndex === 6 }"
          class="px-4 py-2 bg-gray-100 hover:bg-gray-200 focus:outline-none ">企业文化
        </button>
        <!-- Menu content end -->
      </div>

    </div>

  </nav>


  <div v-show="showzxkf" class="fixed bottom-20 md:bottom-36 right-4 md:right-10 flex flex-col z-40 ">

    <router-link @click="toProperty(2)" to="/property" role="menuitem" class="  right-10 group block max-w-xs mx-auto rounded-lg p-2 mb-2 bg-white   ring-1 ring-slate-900/5 shadow-lg space-y-3
       hover:bg-red-700 hover:ring-red-700">
      <div class="flex   space-x-3  justify-center bg-opacity-90">
        <img class="group-hover:content-[url('@/assets/mmbuy/mzc02.png')]  " src="@/assets/mmbuy/mzc01.png" />
      </div>
      <p class="text-slate-500 bg-opacity-90 group-hover:text-white text-sm ">买卖资产
      </p>
    </router-link>


    <a class="group block max-w-xs mx-auto rounded-lg p-2 mb-2 bg-white ring-1 ring-slate-900/5 shadow-lg space-y-1
       hover:bg-red-700 hover:ring-red-700" @mouseenter="showWx = true" @mouseleave="showWx = false">
      <div class="flex  space-x-3 justify-center">
        <img class="group-hover:content-[url('@/assets/mmbuy/wxzx02.png')]" src="@/assets/mmbuy/wxzx01.png" />
      </div>
      <p class="text-slate-500 group-hover:text-white text-sm">咨询客服
      </p>
    </a>

  </div>

  <a v-if="isVisible" @click="scrollToTop"   class="z-50 fixed bottom-2 md:bottom-16 right-4 md:right-10 group block max-w-xs mx-auto 
  rounded-lg p-4 bg-white ring-1 ring-slate-900/5 
  shadow-lg space-y-3 hover:bg-red-700 hover:ring-red-700  ">
    <div class="flex items-center space-x-3">
      <!-- <img src="@/assets/mmbuy/top01.png"> -->
      <img class="group-hover:content-[url('@/assets/mmbuy/top02.png')]" src="@/assets/mmbuy/top01.png" />

    </div>
  </a>

  <div v-show="showWx"
    class=" bg-white fixed right-24 md:right-32 bottom-4 md:bottom-52 rounded-lg shadow-lg text-center p-2 z-50">
    <img src="@/assets/mmbuy/hz/hz08.png" alt="" class="w-[180px]  mb-1">
    <p class="text-sm text-gray-600 mb-4">微信扫码，咨询客服</p>
  </div>

 


  <button class="flex flex-col group  fixed bottom-1/3 right-0 max-w-xs mx-auto rounded-lg p-2 mb-2 bg-gray-600 ring-1 ring-slate-900/5 shadow-lg space-y-1
       hover:bg-red-700 hover:ring-red-700 " @click="showzxkf = !showzxkf" >
    <div class="animate-bounce"><i class="fa-solid fa-phone text-white "></i></div>
    
    <div class="text-white">联</div>
    <div class="text-white">系</div>
    <div class="text-white">我</div>
    <div class="text-white">们</div>
  </button>



  <!-- Navbar end -->
</template>

<script>
import {
  Dialog, DialogPanel,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/vue'

// import { is } from 'core-js/core/object';

// import e from 'express';

export default {
  components: {
    Disclosure,
    DisclosureButton,
    Dialog,
    DialogPanel,
    DisclosurePanel,
  },

  data() {
    return {
      navigation: [
        { title: '首页', to: '/' },
        {
          title: '关于我们',
          to: '/about',
          children: [
            {
              title: '董事长的初心',
              to: '/about/1',
            },
            {
              title: '公司简介',
              to: '/about/2',
            },
            {
              title: '企业领导',
              to: '/about/3',
            },
            {
              title: '顾问团领导',
              to: '/about/7',
            },
            {
              title: '领导团队',
              to: '/about/4',
            },
            {
              title: '组织架构',
              to: '/about/5',
            },
            {
              title: '企业文化',
              to: '/about/6',
            },
          ],
        },
        { title: '资产信息', to: '/property' },
        { title: '招商合作', to: '/cooper' },
        { title: '联系我们', to: '/contact' },

      ],
      active: 0,
      activeIndex: 0,
      moreVisible: false,
      isVisible: false,
      showWx: false,
      mobileMenu: false,
      lastScrollTop: 0, // 上次滚动位置  

      showzxkf: false

    };
  },
  created() {
    window.addEventListener('scroll', this.handlerScroll);
  },
  methods: {
    isActive(path) {

      if (path === '/') {
        this.active = 0;
      } else if (path === '/about') {
        this.active = 1;
      } else if (path === '/property') {
        this.active = 2;
      } else if (path === '/cooper') {
        this.active = 3;
      } else if (path === '/contact') {
        this.active = 4;
      }

    },


    setActive(activeTab) {
      this.active = 1;
      this.activeIndex = activeTab;
      this.$router.push({ name: 'AboutView', params: { activeTab } });
    },
    showMore(visible) {
      this.moreVisible = visible;
    },

    // handleScroll() {
    //   this.isVisible = window.scrollY > 300;
    // },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },

    toProperty(index) {
      console.log("index", index);
      this.active = 2
      this.$router.push({ name: 'PropertyView', params: { index } });
      // this.$router.replace({ name: 'PropertyView', query: { index:index } });
      // this.$router.push({ name: 'PropertyView', query: { index:index } });  
    },
    handlerScroll(event) {
      

      const currentScrollTop = event.target.scrollTop || window.scrollY; // 获取当前滚动位置  
      if (currentScrollTop > this.lastScrollTop) {
        // 向下滚动  

        this.isVisible = false;
      } else {
        // 向上滚动  

        this.isVisible = true;
        if (currentScrollTop <= 0)
          this.isVisible = false;

      }
      this.lastScrollTop = currentScrollTop; // 更新上次滚动位置  
    },

  }
};
</script>

<style>
/* 添加活动链接样式 */
.active-link p {
  color: #BF242A;
  /* 活动链接变红色 */
  font-weight: bold;
  /* 活动链接加粗 */
}

/* .px-percent-10 {  
    padding-left: 15%;  
    padding-right: 15%;  
}   */



.select p {
  font-family: Microsoft YaHei;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #BF242A;
}

.mmlogo {
  width: 279.71px;
  height: 44px;
  top: 38px;
  left: 360px;
  gap: 0px;
  margin-left: 4rem;
  margin-top: 1rem;


  @media screen {
    width: 279.71px;
    height: 44px;
    top: 38px;
    left: 360px;
    gap: 0px;
    margin-left: 1rem;
    margin-top: 1rem;
  }
}
</style>
