<template bg-red>

	<!-- Main section start -->
	<main-section></main-section>
	<!-- Main section end -->

</template>

<script>
import MainSection from '@/sections/MainSection.vue';

export default {
  name: 'HomeView',
	components: {
		MainSection,
	}
}
</script>

<style scoped>


</style>
