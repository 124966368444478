<template>
  <!-- 示例内容 -->
   
  <footer class="w-full bg-C272A6F text-white py-10 ">
    <div class="max-w-screen-1200 mx-auto flex flex-col md:flex-row justify-between items-center px-4">
      <!-- 左侧内容 -->
      <div class="text-center md:text-left mb-4 md:mb-0 footer ">
        <router-link to="/" role="menuitem">
          <img src="@/assets/mmbuy/foot-logo.png" alt="Logo" class="h-[46px] inline-block mb-5 ">
          <!-- <span alt="Logo" class="h-[6px] w-[46px] inline-block  "></span> -->

          <img src="@/assets/mmbuy/foot-logo1.png" alt="Logo" class="h-[46px] inline-block mb-5 md:ml-12 ">
        </router-link>

       
      </div>
      
       
      
    </div>

    <div class="border-b border-BG52558C mt-6"></div>

    <!-- 底部第二部分 -->
    <div class="max-w-screen-1200 mx-auto flex flex-col md:flex-row justify-between items-center px-4 mt-6">
   
      <div class=" md:text-left mb-4 md:mb-0 footer ">
        

        <p class="text-sm text-white font-bold">联系电话：0411- 84641106 </p>
        <p class="text-sm text-white font-bold"><span class="text-C272A6F">联系电话：</span>18624266888（李先生） </p>

        <p class="text-sm text-white font-bold">联系地址：辽宁省大连市沙河口区富鸿国际大厦A座13层</p>
      </div>
     
      <div class="flex items-center">
       
        <div class=" mx-4">
          <p class="text-sm text-white">目前平台各项基础建设工作已经完善，平台已于2024年7月18日上线运营。</p>
          <p class="text-sm text-white">百度可查到《中国全球商品买卖网》官网。</p>
          <p class="text-sm text-white">可在苹果、华为、荣耀、小米手机应用市场搜索下载“24小时买卖网”APP 。</p>


        </div>
      
      </div>
    </div>

    <div class="border-b border-BG52558C mt-6"></div>


    <div class="mt-4 md:mt-12 max-w-screen-1200 mx-auto text-left text-sm px-4 text-white">©2024 中国全球商品买卖网&二十四小时买卖网(大连)科技有限公司 版权所有 <a  
          href="http://www.beian.miit.gov.cn"> 备案号：辽ICP备2024033249号 辽ICP备2024033249号-1</a></div>

 


  </footer>







</template>

<script>
export default {
}
</script>

<style>
 
</style>
